<template>
  <div class="dashboard">
    <div class="data">
      <div class="grid-wrapper grid-wrapper--top">
        <div v-for="d in data"
             :key="d.name"
             class="grid-tile">
          <label class="grid-tile__label">
            {{ $t(`oapps.mean.${d.label.toLowerCase()}`) }}
          </label>
          <span class="data-value grid-tile__value">
            {{ d.value ? `${d.value.toFixed(2)} ${d.unit}` : $t('oapps.commons.noData') }}
          </span>
        </div>
      </div>
      <div class="grid-wrapper">
        <GraphCard v-for="(item,id) in graphData"
                   :key="id"
                   :title="item.name"
                   :class="(sidebarExpanded ? 'graph-card-extended' : 'graph-card')">
          <graph-extended v-if="sidebarExpanded"
                          :data="item.values"
                          :unit="item.unit" />
          <graph-sidebar v-if="!sidebarExpanded"
                         :data="item.values"
                         :unit="item.unit" />
        </GraphCard>
      </div>
    </div>
  </div>
</template>

<script>
import GraphCard from '@/oapps/commons/graph-card.vue';
import GraphSidebar from '@/oapps/commons/graph-sidebar.vue';
import GraphExtended from '@/oapps/commons/graph-extended.vue';

import graphMixin from '@/oapps/mixins/graph.js';

export default {
  name: 'PresenceDashboard',
  components: { GraphCard, GraphSidebar, GraphExtended },
  mixins: [graphMixin],
  props: {
    sidebarExpanded: {
      type: Boolean,
      default: false,
    },
    oapp: {
      type: Object,
      default: null,
    },
    limits: {
      type: Object,
      default: () => ({ min: 0, max: 0 }),
    },
    global: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      data: [
        {
          label: 'Presence',
          name: 'presence.value',
          value: undefined,
          unit: '%',
        },
      ],
      types: [],
    };
  },
  computed: {
    dataClass() {
      return this.sidebarExpanded ? 'data__label col-1' : 'data__label col-5';
    },
  },
  watch: {
    global: {
      handler(val) {
        if (!this.creatingData) this.createData();
        this.$emit(
          'input',
          val && val.total !== undefined && val.total !== null
            ? { label: `${val.total.toFixed(2)} ${this.data[0].unit}`, value: val.total }
            : null
        );
      },
      deep: true,
    },
  },
};
</script>

<style lang="stylus" scoped>
@import '~variables'

.dashboard
  .select-field
    top 8px
    left 10px
    font-weight 700
    font-size $fs-h3
  .type-selector
    margin 10px 10px 0px 10px
  .graph-card
    margin-bottom $border-thick
    max-width 400px
    width 100%
    &:last-child
      margin-bottom 0
  .graph-card
    max-width 2000px

.grid-tile__label
  margin-bottom $space-2
</style>
